/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline*/
/**
 * Module for header on Orders page
 * @module src/OrdersHeader
 */
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
// import Popover from '@salesforce/design-system-react/components/popover';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_FORM_CONFIGURATIONS } from '../../graphql/getFormConfigurations';
import { getEventIcon } from '../../util/utilityFunctions';
import { getUserInfo } from '../../store/ducks/userInfo';
import { ORDERS, USAGE_TYPE, MULTIPLE_PDF_PERSONAS } from '../../util/constants';
import UsageSheetForm from '../Usage/UsageSheetForm';
import CancelEventModal from '../CreateEditSurgeryEvent/CancelEventCreationModal';

interface Props {
  label: string;
  handleOrderSearch: (event: any, val: string) => void;
  handleToggleFilterOrder: () => void;
  handleGeneratePdf?: () => void;
  enableGenerate?: boolean;
}

interface UserInfo {
  contactId?: string;
  division: string | null;
  divisions: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  personas: string;
  userName: string | null;
  userType: string;
  businessunit: string;
  assignedScreens: any;
  businessRules: string[];
}

const OrdersHeader: FC<Props> = ({
  label,
  handleOrderSearch,
  handleToggleFilterOrder,
  handleGeneratePdf,
  enableGenerate,
}) => {
  const userInfo: UserInfo | null = useSelector(getUserInfo);
  const multiplePdfToggle: any = userInfo?.businessRules?.find(
    (br: any) => br.rules === 'Allow to generate Multiple PDF'
  );
  const isMultiplePdfPersonas =
    userInfo && MULTIPLE_PDF_PERSONAS?.find((item: any) => item === userInfo?.personas);
  const [orderType, setOrderType] = useState('');
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const requiredForms = userInfo?.assignedScreens;
  const [showUsageSheet, setShowUsageSheet] = useState(false);
  const [getFormConfigurations, { data }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  let DATA = [
    {
      label: 'Bill Only',
      value: USAGE_TYPE.BILL_ONLY,
    },
    {
      label: 'Ship & Bill',
      value: USAGE_TYPE.SHIP_AND_BILL,
    },
  ];
  if (requiredForms?.find((rec: any) => rec.screen === 'Bill Only - Create' && !rec.isRequired)) {
    DATA = [
      {
        label: 'Ship & Bill',
        value: USAGE_TYPE.SHIP_AND_BILL,
      },
    ];
  }
  if (requiredForms?.find((rec: any) => rec.screen === 'Ship & Bill - Create' && !rec.isRequired)) {
    DATA = [
      {
        label: 'Bill Only',
        value: USAGE_TYPE.BILL_ONLY,
      },
    ];
  }

  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    setShowUsageSheet(!showUsageSheet);
  };

  const handleUsageSheetToggle = useCallback((): void => {
    setShowUsageSheet(!showUsageSheet);
  }, [showUsageSheet]);

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      {!(label === 'Review') && (
        <>
          {multiplePdfToggle?.isRequired &&
            (label === 'Open' || label === 'Processed') &&
            isMultiplePdfPersonas && (
              <Button
                label="Generate PDF"
                iconCategory="utility"
                iconName="paste"
                onClick={handleGeneratePdf}
                disabled={!enableGenerate}
              />
            )}
          <ButtonGroup variant="list" id="button-group-page-header-actions">
            <Dropdown
              iconCategory="utility"
              iconSize="small"
              align="right"
              iconName="add"
              iconPosition="left"
              disabled={
                !requiredForms?.find(
                  (rec: any) =>
                    (rec.screen === 'Ship & Bill - Create' && rec.isRequired) ||
                    (rec.screen === 'Bill Only - Create' && rec.isRequired)
                )
              }
              label="New"
              onSelect={(selectedOption: any): void => {
                setOrderType(selectedOption.value);
                handleUsageSheetToggle();
              }}
              options={DATA}
            />
          </ButtonGroup>
        </>
      )}
    </PageHeaderControl>
  );

  const headerControls = (): ReactNode => (
    <>
      <PageHeaderControl className="slds-m-left_large">
        <div className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
          <div className="slds-col_padded">
            <Input
              iconRight={
                <InputIcon
                  assistiveText={{
                    icon: 'Search',
                  }}
                  name="search"
                  category="utility"
                />
              }
              id="search-case-id"
              placeholder="Search"
              onChange={(_event: any, searchItem: string): void =>
                handleOrderSearch(_event, searchItem)
              }
            />
          </div>
        </div>
      </PageHeaderControl>
      <PageHeaderControl className="slds-m-left_large">
        <Button
          iconCategory="utility"
          className="slds-text-color_default"
          iconName="filterList"
          label="Filter"
          iconPosition="left"
          onClick={handleToggleFilterOrder}
        />
        {/* <div className="slds-dropdown-trigger slds-dropdown-trigger_click filter-preset-popover">
            <Popover
              align="bottom"
              isOpen={filterPresetOpen}
              onClose={handleFilterPresetPopoverClose}
              onRequestClose={handleFilterPresetPopoverClose}
              ariaLabelledby=""
              body={
                <div className="filter-preset_dropdown">
                  <ul className="slds-dropdown__list" role="menu" aria-label="Show More">
                    <li className="slds-dropdown__item" role="presentation" key="reset">
                      <Button
                        className="slds-button slds-button_neutral filter-preset-reset-button"
                        onClick={resetFilters}
                      >
                        Reset
                      </Button>
                    </li>
                    {filterPresets &&
                      filterPresets.map((filter: FilterPreset) => {
                        return (
                          <li
                            className="slds-dropdown__item"
                            role="presentation"
                            key={filter.filterExternalId}
                          >
                            <Button
                              className="slds-button slds-button_neutral filter-preset-heading-button"
                              onClick={handleFilterEventItems(
                                filter.filterValues,
                                filter.filterName
                              )}
                            >
                              {filter.filterName}
                            </Button>
                            <Button
                              className="slds-button slds-button_neutral filter-preset-edit-button"
                              onClick={handleEditPress(filter)}
                            >
                              Edit
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              }
              id="popover-walkthrough"
            >
              <Button
                className="filter-drop-trigger"
                iconCategory="utility"
                iconName="filter"
                iconPosition="left"
                label="Filter Presets"
                onClick={handleFilterPresetPopover}
              />
            </Popover>
          </div> */}
      </PageHeaderControl>
    </>
  );

  const iconPath = getEventIcon(ORDERS);

  return (
    <div>
      <IconSettings iconPath="/icons">
        <PageHeader
          className="events_header"
          icon={<img src={iconPath} className="logo-image" alt="orders" />}
          label="Orders"
          onRenderActions={headerActions}
          onRenderControls={headerControls}
          title={label === 'Review' ? 'Review Queue' : `All ${label} List`}
          truncate
          variant="object-home"
        />
        {showUsageSheet && (
          <UsageSheetForm
            eventUsage={false}
            isOpen={showUsageSheet}
            toggleOpen={handleUsageSheetToggle}
            handleCancelButton={handleCancelButton}
            formData={data}
            orderType={orderType}
          />
        )}
        {canceModalVisible && (
          <CancelEventModal
            isInventory
            canceModalVisible={canceModalVisible}
            handleCancelModal={handleCancelModal}
            handleCancelModalConfirmPress={handleCancelModalConfirmPress}
          />
        )}
      </IconSettings>
    </div>
  );
};

export default OrdersHeader;
